/** @format */

import {
	getUsersAPI,
	deleteUserAPI,
	postUsersAPI,
	updateUsersAPI,
	viewUsersAPI,

} from "../api";
import Instance from "../axios";

const API_URL = process.env.REACT_APP_API;

const getUsers = async () => {
	try {
		const response = await Instance.get(API_URL + getUsersAPI);
		console.log("response", response.data.users.data)
		return response.data.users.data;
	} catch (error) {
		// console.log("New Test", error);
	}
};

 

const postUsers = async (data) => {
	try {
		const response = await Instance.post(API_URL + postUsersAPI, data);
		return response;
	} catch (error) {
		throw error;
	}
};

const updateUsers = async (userId, data) => {
	console.log("API_URL + updateUsersAPI + userId", API_URL + updateUsersAPI + userId)
	try {
		let response = await Instance.put(API_URL + updateUsersAPI + userId, data);
		return response;
	} catch (error) {
		throw error;
	}
};

const viewUser = async (id) => {

	console.log("id", id)
	console.log("API_URL + viewUsersAPI",API_URL + viewUsersAPI + id)
	try {
		const response = await Instance.get(API_URL + viewUsersAPI + id);
		console.log("hello user",response)
		return response.data;
	} catch (error) {
		throw error;
	}
};

const deleteUser = async (id) => {
	try {
		const response = await Instance.delete(API_URL + deleteUserAPI + id);
		return response;
	} catch (error) {
		throw error;
	}
};


export {
	getUsers,
	deleteUser,
	viewUser,
	updateUsers,
	postUsers
};
