import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
// component
import Iconify from '../Iconify';


// Icons
import PublishIcon from '@mui/icons-material/Publish';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import VisibilityIcon from '@mui/icons-material/Visibility';

// ----------------------------------------------------------------------


export default function UserMoreMenu({ status, callbackDelete = () => { }, editLink = '/', allowedPermisssions = { allowedEdit: true, allowedDelete: true, allowedReadyForPublish: true }, callbackPublish = () => { }, callbackRejection = () => { }, callbackView = () => { }, module, callbackForPublish = () => { }, callbackForUnPublish = () => { } }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
console.log("editLink", editLink);
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)} className="ThreeDotsColor">
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        onClick={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
       <div> 
    {/* <MenuItem component={RouterLink} to={editLink} sx={{ color: 'text.secondary' }} key="4">
                <ListItemIcon>
                  <Iconify icon="eva:edit-fill" width={24} height={24} />
                </ListItemIcon>
                <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
              </MenuItem>     */}
              <MenuItem onClick={callbackDelete} sx={{ color: 'text.secondary' }} key="5">
                <ListItemIcon>
                  <Iconify icon="eva:trash-2-outline" width={24} height={24} />
                </ListItemIcon>
                <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
              </MenuItem>  </div>
      </Menu>
    </>
  );
}
