/** @format */

import axios from "axios";
import toast from "react-hot-toast";
import { loginApi } from "../api";
import Instance from "../axios";

const API_URL = process.env.REACT_APP_API;

const register = (username, email, password) => {
	return axios.post(API_URL + "/register", {
		username,
		email,
		password,
	});
};

const login = (email, password) => {
	return Instance.post(API_URL + loginApi, {
		email,
		password,
	})
		.then((response) => {
			if (response.data) {
				localStorage.setItem("user", JSON.stringify(response.data));
			}

			return response.data;
		})
		.catch((error) => {
			// throw error;
			// console.log('showing up', error.response.data.message);
			toast.error(error.response.data.message);
			// return JSON.stringify(error.response);
		});
};

const logout = () => {
	localStorage.setItem("user",'');
};

const authService = {
	register,
	login,
	logout,
};

export default authService;
