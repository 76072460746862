/**
 
 * @format
 */
export const urlPath = process.env.REACT_APP_API;
/*-----Login Api-----*/

export const loginApi = "/login";
export const register = "/signup";
export const logout = "/logout";
export const cookieSanctum = '/sanctum/csrf-cookie'

/*-----Users Api-----*/

export const getUsersAPI = "/users";
export const deleteUserAPI = "/users/";
export const postUsersAPI = "/registration";
export const viewUsersAPI = "/users/";
export const updateUsersAPI = "/users/";


/*-----Properties Api-----*/
export const getPropertiesAPI = "/sheets_data";



/*-----Booking Api-----*/
export const getBookingAPI = "/booking";