import * as React from "react";
import { useState, useEffect } from 'react'

import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import { Link, useNavigate , NavLink} from "react-router-dom";
import Cookies from "js-cookie";
import logo from "../assets/images/logo.png"
import Instance from "../store/axios";
import toast from "react-hot-toast";




export default function Header() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showUser, setShowUser] = useState(false);


  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // console.log("userRole", userRole)
  const UserCookies = Cookies.get("user"); // => 'value'
  console.log("UserCookies", UserCookies);
  
  let userinfo = {};

  if(UserCookies){
    userinfo = JSON.parse(UserCookies);
    console.log("userinfo", userinfo);
  }
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  const handleLogout = () => {
    // Instance.post('/logout')
    // .then((res) => {
    //   console.log("res",res);
    //     toast.success('Logout Successfully!');
    //     setTimeout(() => {
    //       console.log('running'); 
    //       navigate('/login');
    //     }, 2000);        
    // })
    // .catch((error) =>
    // { 
    //     // toast.error(error.response.data.message);
    //     console.log("Error",error); 
    // }
    // )
    Cookies.remove("token");
    navigate("/login");
  };


  useEffect(() => {
    if (userinfo.role === "Admin") {
      setShowUser(true);
    }
   
  }, [userinfo.role]);

  return (
    <>
      <nav className="header">
        <div>
        <Link to="/">
          <img src={logo} alt="logo" className="logo-header" />
          </Link>
        </div>
        <div>
          <Box
            sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
          >
             <NavLink to="/" exact activeClassName="active">
          <Typography sx={{ minWidth: 100 }}>Home</Typography>
          </NavLink>

            {showUser ? (
           <>
          <NavLink to="/users" exact activeClassName="active">
          <Typography sx={{ minWidth: 100 }}>All Users</Typography>
          </NavLink>
          </>              
            ) : (
              ""
            )}

             <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {/* <MenuItem onClick={handleClose}>
              <Avatar /> My Profile
            </MenuItem> */}

            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </div>
      </nav>
    </>
  );
}
