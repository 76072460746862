import React from 'react'
import Header from '../components/Header'




const MainTheme = (props) => {
  return (
    <div>
<Header />
{props.children} 
    </div>
  )
}

export default MainTheme
