/** @format */
// React Imports
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

// material
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  CircularProgress,
  TextField,
  Box,
  Button,
} from "@mui/material";

//Components
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import { UserMoreMenu, UserListHead } from "../components/user";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";

// Api Services
import { getUsers, deleteUser } from "../store/services/usersService";
import Instance from "../store/axios";

// Third Party Import
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { checkPermission } from "../components/Common";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";

// For Filter
import { createFilterOptions } from "@mui/material/Autocomplete";
import { getAllBooking } from "../store/services/properyServices";

// Icons

// ----------------------------------------------------------------------
const defaultValues = {
  start_date: "",
  end_date: "",
  office_locations: "",
  name: ""
};

let TABLE_HEAD = [
  { id: "Company Name", label: "Company Name", alignRight: false },
  { id: "Preparer Name", label: "Preparer Name", alignRight: false },
  { id: "PTIN", label: "PTIN", alignRight: false },
  { id: "First Name", label: "First Name", alignRight: false },
  { id: "Last Name", label: "Last Name", alignRight: false },
  { id: "Last four", label: "Last four", alignRight: false },
  { id: "Transmit Date", label: "Transmit Date", alignRight: false },
  { id: "Process Date", label: "Process Date", alignRight: false },
  { id: "Funded Date", label: "Funded Date", alignRight: false },
  { id: "Preparer Fee Paid", label: "Preparer Fee Paid", alignRight: false },
  { id: "Preparer Fee Date Paid", label: "Preparer Fee Date Paid", alignRight: false },
  { id: "Custom Fee 1 Description", label: "Custom Fee 1 Description", alignRight: false },
  { id: "Custom Fee 1 Amount", label: "Custom Fee 1 Amount", alignRight: false },
];

// Sorting

// .......................................................................

const AllProperties = () => {
  // States
  const [users, setUsers] = useState([]);
  const resultStateRef = useRef({});
  // const totalFeeRef = useRef(0);
  const [totalFeeRef, setTotalFeeRef] = useState({});
  const [resultValues, setResultValues] = useState([]);

  const [loading, setLoading] = useState(false);

  // Pagination
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const schema = yup.object().shape({
    office_locations: yup.string().min(5).required(),
    start_date: yup.string().required(),
    end_date: yup.string(),
    pf_start_date: yup.string(),
    pf_end_date: yup.string(),
  });

  const {
    control,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(schema),
  });



  const onSubmit = (data) => {
    console.log("hheo");
    // const { start_date, office_locations } = data
    // const newData = {start_date:"7684" , office_locations: "DETROIT"};

    setLoading(true);

    const formatDate = (dateString) => {
      console.log("dateString", dateString)
      if(dateString){

        const [year, month, day] = dateString.split("-");
        return `${month}/${day}/${year}`;
      }
    };

    // Create a new object with formatted dates
    const outputJson = {
      "start_date": formatDate(data.start_date),
      "end_date": formatDate(data.end_date),
      "office_locations": data.office_locations,
      "pf_start_date": formatDate(data.pf_start_date),
      "pf_end_date": formatDate(data.pf_end_date),
      "name": data.name
    };
    Instance.post("/sheets_data", outputJson)
      .then((res) => {
        console.log("res test", res.data.result);
        setResultValues(res.data.result);
        // Map method to display data in console
        // Assuming res.data.result is an object

        const result = res.data.result;
        console.log("result", result);
        // Iterate over object properties

        for (const key in result) {
          if (result.hasOwnProperty(key)) {
            console.log("result", result[key]);

            // Save result[key] in state
            resultStateRef.current = {
              ...resultStateRef.current,
              [key]: result[key],
            };
          }
        }
        setLoading(false);

        console.log("resultState a", resultStateRef.current);
        console.log("resultStateLENGTH", resultStateRef.current.length);
        toast.success("User Registered Successfully");
      })
      .catch((error) => {
        toast.error("user already exit");
        console.log("Error", error);
        setLoading(false);

      });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  console.log("hello 2");

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - resultStateRef.current.length)
      : 0;

  useEffect(() => {
    const { totalPreparerFee, totalCustomFee1 } = Object.values(resultValues).reduce(
      (acc, { "Preparer Fee Paid": preparerFee, "Custom Fee 1 Amount": customFee1 }) => {
        acc.totalPreparerFee += parseFloat(preparerFee.replace("$", "").trim()) || 0;
        acc.totalCustomFee1 += parseFloat(customFee1.replace("$", "").trim()) || 0;
        return acc;
      },
      { totalPreparerFee: 0, totalCustomFee1: 0 }
    );
    console.log("setTotalFeeRef", { totalPreparerFee, totalCustomFee1 })
    setTotalFeeRef({ totalPreparerFee, totalCustomFee1 });

    console.log("talFeeRef", totalFeeRef)

  }, [resultValues]);


  // Extract values from the ref object
  //  const resultValues = Object.values(resultStateRef.current);

  return (
    <>
      <div className="container-main">
        <Card sx={{ md: 5 }} spacing={2}>
          <Box
            sx={{
              p: 5,
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
              >
                <FormControl sx={{ mr: 2 }}>
                  <Controller
                    name="office_locations"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        value={value}
                        label="Enter the Locations"
                        onBlur={onBlur}
                        onChange={onChange}
                        focused
                        error={Boolean(errors.office_locations)}
                        placeholder="Lahore"
                      />
                    )}
                  />
                  {errors.office_locations && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {"Location is required"}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl sx={{ mr: 2 }}>
                  <Controller
                    name="start_date"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        value={value}
                        onBlur={onBlur}
                        label="Enter Start Date"
                        onChange={onChange}
                        type="date"
                        focused
                        error={Boolean(errors.start_date)}
                      />
                    )}
                  />
                  {errors.start_date && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {"Start Date is required"}

                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl sx={{ mr: 2 }}>
                  <Controller
                    name="end_date"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        value={value}
                        onBlur={onBlur}
                        label="End Date"
                        onChange={onChange}
                        type="date"
                        focused
                        error={Boolean(errors.end_date)}
                      />
                    )}
                  />
                  {errors.end_date && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {"End Date is required"}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl sx={{ mr: 2 }}>
                  <Controller
                    name="pf_start_date"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        value={value}
                        onBlur={onBlur}
                        label="Preparer Start Date"
                        onChange={onChange}
                        type="date"
                        focused
                        error={Boolean(errors.pf_start_date)}
                      />
                    )}
                  />
                  {errors.pf_start_date && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {"Start Date is required"}

                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl sx={{ mr: 2 }}>
                  <Controller
                    name="pf_end_date"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        value={value}
                        onBlur={onBlur}
                        label="Preparer End Date"
                        onChange={onChange}
                        type="date"
                        focused
                        error={Boolean(errors.pf_end_date)}
                      />
                    )}
                  />
                  {errors.pf_end_date && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {"End Date is required"}
                    </FormHelperText>
                  )}
                </FormControl>


                
                <FormControl sx={{ mr: 2 }}>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        value={value}
                        label="Enter the name"
                        onBlur={onBlur}
                        onChange={onChange}
                        error={Boolean(errors.name)}
                        placeholder="Lahore"
                        focused

                      />
                    )}
                  />
                  {errors.name && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {"Name is required"}
                    </FormHelperText>
                  )}
                </FormControl>


                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{ mt: 1 }}
                  disabled={loading || isSubmitting}

                >
                  {loading ? 'Loading...' : 'Search Data'}
                </Button>
              </form>
            </Box>
          </Box>

          {/* {{users}} */}

          <Scrollbar>
            <TableContainer>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                />
                <TableBody className="properties_tabel">
                  {!loading &&
                    Object.entries(resultValues).map(([page, val], index) => {
                      return (
                        <TableRow hover key={index} className="table-body">
                          {Object.values(val).map((value, colIndex) => (
                            <TableCell key={colIndex} align="left">
                              {value ?? "N/A"}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                  {loading ? (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={resultValues[0]?.length}>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <CircularProgress />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ) : (
                    ""
                  )}
                  {!loading && resultValues?.length === 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell
                        colSpan={13}
                        align="center"
                      >
                        No Result Found

                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <div>

            </div>
          </Scrollbar>

          {totalFeeRef ? (
            <div className="total_amount_bottom">
              <div>
                <p>Total Preparer Fee Paid: </p>
                <p>Total Custom Fee: </p>
              </div>
              <div>
                <p> $ {totalFeeRef.totalPreparerFee}.0000 </p>
                <p> $ {totalFeeRef.totalCustomFee1}.0000 </p>
              </div>

            </div>
          ) : (
            ""
          )}
        </Card>
      </div>
      <Toaster
        position="top-right"
        reverseOrder={false}
        limits={1}
        preventDuplicates
        autoClose={2000}
      />
    </>
  );
};
export default AllProperties;
