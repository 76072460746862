/** @format */

import { useRoutes } from "react-router-dom";
import NotFound from "./pages/NotFound";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./pages/login";
import Register from "./pages/register";
import Users from "./pages/users";
import Protected from "./components/Protected";
import React, {  } from "react";
import AllProperties from "./pages/AllProperties";


export default function App() {

  return useRoutes([
    // { path: "/", element: <Protected Component={Home}  /> },
    // { path: "/", element: <Protected Component={Properties} /> },
    // { path: "/", element: <Protected Component={Home} /> },

    {
      path: "users",
      element: <Protected Component={Users} userRole={"Admin"} />
    },
    // { path: "booking", element: <Protected Component={Booking} userRole={"Admin"}/> },

    // { path: "add-new-user", element: <Protected Component={AddEditUser} userRole={"Admin"}/> },
    // { path: "edit-user/:id", element: <Protected Component={AddEditUser} userRole={"Admin"}/> },
    { path: "/", element: <Protected Component={AllProperties} userRole={"Admin"}/> },
    { path: "signup", element: <Protected Component={Register} userRole={"Admin"}/> },



	
    { path: "login", element: <Login /> },
    { path: "notfound", element: <NotFound /> },

    
    // { path: 'properties', element: <Properties /> },
    // { path: "forget", element: <ForgetPage /> },
    // { path: "newCode", element: <NewPassword /> },

    { path: "*", element: <Login to="/login" replace /> },

    // {
    //   path: "/thanks",
    //   element: <ThankYou />,
    // },
  ]);
}
