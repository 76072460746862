/** @format */
// React Imports
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';

// material
import {
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    Container,
    TableContainer,
    TablePagination,
    CircularProgress,
    TextField,
    Box,
    Button
} from '@mui/material';

//Components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { UserMoreMenu, UserListHead } from "../components/user";


// Api Services
import { getUsers, deleteUser } from "../store/services/usersService";

// Third Party Import
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { checkPermission } from "../components/Common";

// For Filter
import { createFilterOptions } from '@mui/material/Autocomplete';
import Cookies from "js-cookie";

// Icons

// ----------------------------------------------------------------------

let TABLE_HEAD = [
    { id: 'id', label: '#', alignRight: false },
    { id: 'name', label: 'Full Name', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'role', label: 'Role', alignRight: false }, 
    { id: 'Action', label: 'Action', alignRight: true }
];

// Sorting

// .......................................................................

const Users = () => {
    // States
const navigate = useNavigate();

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filtered, setFiltered] = useState(users);
    // Pagination
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    console.log("hello 2")

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    let userinfo = {};
    const UserCookies = Cookies.get("user"); // => 'value'
    console.log("UserCookies", UserCookies);
    if(UserCookies){
      userinfo = JSON.parse(UserCookies);
      console.log("userinfo", userinfo);
    }
    

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;


    //Delete Feature
    const handleDeleteUser = (id) => {
        Swal.fire({
            title: "Are You Sure?",
            text: "you can't undo this",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
        })
            .then((result) => {
                if (result.isConfirmed) {
                    deleteUser(id)
                        .then((r) => {
                            toast.success(r.data.message);
                            setTimeout(() => {
                              console.log('running'); 
                              getUsersApi();
                                                        }, 2000);        
                                             
                        });
                }
            });
    }

   //Get All Users

   const getUsersApi = () => {
    setLoading(true);
    getUsers()
        .then((r) => {
            setUsers(r);
            console.log("r",r)
            setLoading(false);
        })
}

 

    useEffect(() => {
        getUsersApi();
    }, []);


    useEffect(() => {
        if (userinfo.role === "user") {
          navigate('/notfound');
          console.log("hello",userinfo.role)
        }
        else{
          console.log("hello 2",userinfo)
        }
      }, [userinfo.role]);
    
 
    return (
        <>
           <div className="container-main"> 
            <Card sx={{ md: 5 }} spacing={2}>

                <Box sx={{ p: 5, display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h3 className='h3_tag'>Users List</h3>
                    <Box>
                        {/* <TextField
                            type="text"
                            size='small'
                            sx={{ mr: 6, mb: 2 }}
                            placeholder='Search User'
                            // value={newSearch.search}
                            onChange={(e) => handleFilterByName(e, 'search')}
                        /> */}

                        <Button sx={{ textTransform: 'uppercase', fontWeight: '500', }} component={Link} to="/signup" variant='contained'>
                            Add User
                        </Button>

                    </Box>
                </Box>

                <Scrollbar>
                    <TableContainer>
                        <Table>
                         
                            <UserListHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                            {!loading &&
                                            users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        const {
                                            id,
                                            name,
                                            email,
                                            role,
                                        } = row;
                                        return (
                                            <>
                                                <TableRow hover key={id} className="table-body">
                                               
                                                    <TableCell align="left">{id ?? "N/A"}</TableCell>
                                                    <TableCell align="left">{name ?? "N/A"}</TableCell>
                                                    <TableCell align="left">{email ?? "N/A"}</TableCell>
                                                    <TableCell align="left">{role ?? "N/A"}</TableCell>
                                                    {/* <TableCell align="left">{isVerified ?? "N/A"}</TableCell> */}
                                                    <TableCell align="right">
                                                        <UserMoreMenu
                                                            callbackDelete={() => handleDeleteUser(id)}
                                                            // editLink={`/edit-user/${id}`}
                                                        />
                                                    </TableCell>

                                                </TableRow>
                                            </>
                                        )
                                    })}
                                 {loading ? (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={7}>
                                                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                                                        <CircularProgress />
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            ""
                                        )}
                                        {!loading && users.length == 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={7} align="center">
                                                    No data
                                                </TableCell>
                                            </TableRow>
                                        )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={users.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            labelRowsPerPage="Rows per page:"
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            className="tabel-pagination"
                        />
                    </div>
                </Scrollbar>
            </Card></div>
            <Toaster
        position="top-right"
        reverseOrder={false}
        limits={1}
        preventDuplicates
        autoClose={2000} />
        </>
    );
};
export default Users;