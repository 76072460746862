import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";
import toast, { Toaster } from 'react-hot-toast';
import MainTheme from '../themefiles/MainTheme';
 
function Protected(props) {
  const { Component } = props;
  const { userRole } = props;
  // console.log("userRole", userRole)
  // const UserCookies = Cookies.get('user'); // => 'value'
  // const userinfo = JSON.parse(UserCookies);
  // console.log("userinfo", userinfo)

//   const getVerfiyData = () => {
//     if(userinfo.role!==userRole){
//       // Cookies.remove("token");

//       // navigate('/login');
//     }
// }



  const navigate = useNavigate();
  useEffect(() => { 
  const token = Cookies.get('token'); // => 'value'
  
    if (!token) {
       console.log("login is undefined");
      navigate('/login');
    } 
    // getVerfiyData();

  },[]);
  return (
    <>
    <MainTheme>
      <Component />
    </MainTheme>
    </>
  );
}
export default Protected;
