// ** React Imports
import * as React from 'react'
import { useState } from 'react'

// ** Next Imports
import { Link, useNavigate } from "react-router-dom";

// ** MUI Components
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import useMediaQuery from '@mui/material/useMediaQuery'
import OutlinedInput from '@mui/material/OutlinedInput'
import { styled, useTheme } from '@mui/material/styles'
import FormHelperText from '@mui/material/FormHelperText'
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'


// ** Icons Imports
import EyeOutline from 'mdi-material-ui/EyeOutline'
import EyeOffOutline from 'mdi-material-ui/EyeOffOutline'

// ** Third Party Imports
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

// ** Hooks
// import { useAuth } from 'src/hooks/useAuth'
import { useSettings } from '../themefiles/useSettings'

// ** Configs
import themeConfig from '../themefiles/themeConfig'


// ** Layout Import
import BlankLayout from '../themefiles/BlankLayout'

// ** Demo Imports
// import FamilyTimeLogo from "../../assets/images/familytime-logo.png";
import FooterIllustrationsV2 from '../themefiles/FooterIllustrationsV2';

// import FooterIllustrationsV2 from 'src/views/pages/auth/FooterIllustrationsV2'
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Cookies from "js-cookie";
// import { login } from "../../store/slices/auth";
import { login } from "../store/slices/auth";
import Instance from '../store/axios';
import Logo from "../assets/images/logo.png";




// ** Styled Components
const LoginIllustrationWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(20),
  paddingRight: '0 !important',
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(10)
  }
}))

const LoginIllustration = styled('img')(({ theme }) => ({
  maxWidth: '48rem',
  [theme.breakpoints.down('xl')]: {
    maxWidth: '38rem'
  },
  [theme.breakpoints.down('lg')]: {
    maxWidth: '30rem'
  }
}))

const RightWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('md')]: {
    maxWidth: 400
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: 450
  }
}))

const BoxWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('md')]: {
    maxWidth: 400
  }
}))

const TypographyStyled = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  letterSpacing: '0.18px',
  marginBottom: theme.spacing(1.5),
  [theme.breakpoints.down('md')]: { marginTop: theme.spacing(8) }
}))

// const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
//   '& .MuiFormControlLabel-label': {
//     fontSize: '0.875rem',
//     color: theme.palette.text.secondary
//   }
// }))

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(5).required()
})

const defaultValues = {
  email: '',
  password: '',
}

const LoginPage = () => {
  const dispatch = useDispatch();
  // const user = useSelector((state) => state.auth.user);
  // const token = useSelector((state) => state.auth.token); 
  const token = Cookies.get('token'); // => 'value'

  const loginLoading = useSelector((state) => state.auth.loading);

  const [showPassword, setShowPassword] = useState(false)

  // ** Hooks
  // const auth = useAuth()
  const theme = useTheme()
  const { settings } = useSettings()
  const hidden = useMediaQuery(theme.breakpoints.down('md'))

  // ** Vars
  const { skin } = settings

  const navigate = useNavigate();

  const {
    control,
    setError,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const onSubmit = data => {
    // console.log("Data",data);
    // dispatch(login({email:data.email,password:data.password}))
    Instance.post('/login', data)
      .then((res) => {
        // console.log("res", res);
        const token = res.data.access_token;
        const user = JSON.stringify(res.data.data);
        // console.log("token", token)
        // console.log("user 11", user)
        Cookies.set("token", token);
        Cookies.set("user", user);
        toast.success('Login Is Successfully!');
        setTimeout(() => {
          // console.log('running');
          navigate('/');
        }, 2000);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);
        console.log("Error", error);
      }
      )

    // const { email, password } = data
    // auth.login({ email, password }, () => {
    //   setError('email', {
    //     type: 'manual',
    //     message: 'Email or Password is invalid'
    //   })
    // })
  }
  useEffect(() => {
    console.log('setuped token', token);
    if (token) {
      toast.success("you already login");
      setTimeout(function () {
        navigate("/");
      }, 2000);
    }
  }, []);
  const imageSource = skin === 'bordered' ? 'auth-v2-login-illustration-bordered' : 'auth-v2-login-illustration'

  return (
    <>
      <Box className='content-right login-page-logo merge-flex'>
        {!hidden ? (
          <Box sx={{ flex: 1, display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'center' }}>
            <LoginIllustrationWrapper>
              <LoginIllustration className="main-image" alt='login-illustration' src={`/images/pages/${imageSource}-${theme.palette.mode}.png`}
              />
            </LoginIllustrationWrapper>
            <FooterIllustrationsV2 />
          </Box>
        ) : null}
        <RightWrapper sx={skin === 'bordered' && !hidden ? { borderLeft: `1px solid ${theme.palette.divider}` } : {}}>
          <Box
            sx={{
              p: 7,
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'background.paper'
            }}
          >
            <BoxWrapper>
              <Box
                sx={{
                  top: 30,
                  left: 40,
                  display: 'flex',
                  position: 'absolute',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                className="logo-top"
              >
                <img src={Logo} alt='logo' />
              </Box>
              <Box sx={{ mb: 3 }}>
                <TypographyStyled variant='h5' className='Heading_color'>{`Welcome to ${themeConfig.templateName}! 👋🏻`}</TypographyStyled>
                <Typography variant='body2' className='Paragraph_color'>Please sign-in to your account and start the adventure</Typography>
              </Box>
              <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <Controller
                    name='email'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        autoFocus
                        label='Email'
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        error={Boolean(errors.email)}
                        placeholder='admin@mail.com'
                      />
                    )}
                  />
                  {errors.email && <FormHelperText sx={{ color: 'error.main' }}>{errors.email.message}</FormHelperText>}
                </FormControl>
                <FormControl fullWidth sx={{ mb: 4 }}>
                  <InputLabel htmlFor='auth-login-v2-password' error={Boolean(errors.password)}>
                    Password
                  </InputLabel>
                  <Controller
                    name='password'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <OutlinedInput
                        value={value}
                        onBlur={onBlur}
                        label='Password'
                        onChange={onChange}
                        id='auth-login-v2-password'
                        error={Boolean(errors.password)}
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton
                              edge='end'
                              onMouseDown={e => e.preventDefault()}
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? <EyeOutline /> : <EyeOffOutline />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                  {errors.password && (
                    <FormHelperText sx={{ color: 'error.main' }} id=''>
                      {errors.password.message}
                    </FormHelperText>
                  )}
                </FormControl>


                <Button fullWidth size='large' type='submit' variant='contained' className="login-button" sx={{ mb: 7 }}
                  disabled={loginLoading}>
                  Login
                </Button>
              

              </form>
            </BoxWrapper>
          </Box>
        </RightWrapper>
      </Box>
      <Toaster
        position="top-right"
        reverseOrder={false}
        limits={1}
        preventDuplicates
        autoClose={2000} />
    </>
  )
}
LoginPage.getLayout = page => <BlankLayout>{page}</BlankLayout>
LoginPage.guestGuard = true

export default LoginPage
