import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from './message';
import AuthService from '../services/auth.service';
const user = JSON.parse(localStorage.getItem('user'));

export const register = createAsyncThunk('auth/register', async ({ username, email, password }, thunkAPI) => {
  try {
    const response = await AuthService.register(username, email, password);
    thunkAPI.dispatch(setMessage(response.data.message));
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage(message));
    return thunkAPI.rejectWithValue();
  }
});

export const login = createAsyncThunk('auth/login', async ({ email, password }, thunkAPI) => {
  try {
    const data = await AuthService.login(email, password);
    console.log("data", data)
    return { user: data };
  } catch (error) {
    console.log("err", error)
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error || error.toString();
    thunkAPI.dispatch(setMessage(message));
    return thunkAPI.rejectWithValue();
  }
});

export const logout = createAsyncThunk('auth/logout', async () => {
  await AuthService.logout();
});

const initialState = user ? { isLoggedIn: true, user, token: user.token , loading:false } : 
                            { isLoggedIn: false, user: null, token: null , loading:false };

const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
      if(action.payload.user?.token){
        state.token = action.payload.user.token;
      }
      state.loading = false;
    },
    [login.pending]: (state, action) => {
      // console.log('checking token',action.payload);
      state.loading = true;
    },
    [login.rejected]: (state, action) => {
      // toast.error(action.error.message);
      state.loading = false;
      // toast.error(action.payload.data.message);
    },
    [register.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
    },
    [register.rejected]: (state, action) => {
      state.isLoggedIn = false;
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
  },
});

const { reducer } = authSlice;
export default reducer;
